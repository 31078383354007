import Head from "next/head";
import dynamic from "next/dynamic";

const DynamicLanding = dynamic(() => import("@/components/Landing"));
const DynamicPopular = dynamic(() => import("@/components/Popular"));
const DynamicNewsletter = dynamic(() => import("@/components/Newsletter"));

const Home = () => {
  const metaTag = {
    image:
      "https://firebasestorage.googleapis.com/v0/b/portfolio-83773.appspot.com/o/Media%2FProjects%2FStoicspace%2Fbanner.png?alt=media&token=3678fbc1-1b83-4edd-979b-725625873d9e",
    url: "https://www.stoicspace.com",
    description:
      "Learn about stoicism from philosophers like Seneca and Marcus Aurelius with this informative blog and quote website.",
    title: "stoicspace — Built for the modern stoic.",
  };

  return (
    <>
      <Head>
        <meta property="og:image" content={metaTag.image} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1024" />
        <meta property="og:image:height" content="1024" />
        <meta property="og:url" content={metaTag.url} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content={metaTag.image} />
        <meta name="twitter:description" content={metaTag.description} />
        <meta property="og:description" content={metaTag.description} />
        <meta name="description" content={metaTag.description} />

        <title>{metaTag.title}</title>
        <meta property="og:title" content={metaTag.title} key="title" />
        <meta name="twitter:title" content={metaTag.title} />
      </Head>
      <DynamicLanding />
      <DynamicPopular />
      <DynamicNewsletter />
    </>
  );
};

export default Home;
